.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.horizontal-scroll {
  display: flex;
  list-style: none;
  height: 300px;
  overflow-x: scroll;
  padding: 20px 0;
  margin: 0 auto;
}

.horizontal-scroll::-webkit-scrollbar {
  height: 5px;
  background: rgba(255, 255, 255, 0);
}

.horizontal-scroll::-webkit-scrollbar-thumb {
  background: var(--accent);
}

.horizontal-scroll li {
  flex: 0 0 200px;
  background: var(--accent);
  margin: 0 20px 0 0;
}

.horizontal-scroll li:last-of-type {
  margin: 0;
}

.vertical-scroll {
  display: flex;
  flex-direction: column;
  list-style: none;
  width: 300px;
  overflow-y: scroll;
  padding: 20px 0;
  margin: 0 auto;
}

.vertical-scroll::-webkit-scrollbar {
  width: 5px;
  background: #fff3;
}

.vertical-scroll::-webkit-scrollbar-thumb {
  background: var(--accent);
}

.vertical-scroll li {
  flex: 0 0 200px;
  background: var(--accent);
  margin: 0 0 20px 0;
}

.vertical-scroll li:last-of-type {
  margin: 0;
}

#progress .indicator {
  stroke: blue;
  stroke-width: 10;
  fill: none;
}

body.light-theme #progress .indicator {
  stroke: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@import url('https://fonts.googleapis.com/css2?family=Bungee+Shade&family=Wallpoet&family=Zen+Dots&display=swap');